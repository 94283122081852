import axios from "axios";

const STAGING_BASE_URL = "https://twvictvs.victvs.co.uk/vx/api/v0/vx";
const STAGING_BASE_ADMIN_URL = "https://twvictvs.victvs.co.uk/vx/adminapi/v0/vxadmin";

const BASE_URL = "https://vxadmin.victvsone.com/api/v0/vx/";
const BASE_ADMIN_URL = "https://vxadmin.victvsone.com/adminapi/v0/vxadmin/";
const BASE_API_KEY = "678e22bdb7d12c297cba77089950d84d";
const BASE_ADMIN_API_KEY = "d194J8HprfVQ63u";

const API_DETAILS = {
  secretName: BASE_URL,
  baseUrl: BASE_URL,
  baseAdminUrl: BASE_ADMIN_URL,
  baseApiKey: BASE_API_KEY,
  baseAdminApiKey: BASE_ADMIN_API_KEY,
};

const getUserRoles = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const roles = user?.roles;
  return roles;
};

const getTimeZone = () => {
  const date = new Date();
  const offset = date.getTimezoneOffset();
  const minutes = offset % 60;
  const hours = Math.floor(offset / 60);

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
};

const padTo2Digits = num => num.toString().padStart(2, "0");

const getLocalLanguage = () => {
  const userLocale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;

  const language = userLocale.split("-")[0];
  return language;
};

export const getURL = (video = false) => {
  const roles = getUserRoles();
  const language = getLocalLanguage();

  const Axios = axios.create({
    baseURL: roles?.includes(1) ? API_DETAILS.baseAdminUrl : API_DETAILS.baseUrl
  });
  const apiKey = roles?.includes(1) ? API_DETAILS.baseAdminApiKey : API_DETAILS.baseApiKey;

  Axios.defaults.headers.common["Content-Type"] = video ? "multipart/form-data" : "application/json";
  Axios.defaults.headers.common["X-Custom-Language"] = language;
  // Axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  // Axios.defaults.headers.common["X-Custom-Timezone"] = timezone;
  Axios.defaults.headers.common["Authorization"] = `Bearer ${apiKey}`;

  return Axios;
};

export const getBaseURL = (video = false) => {
  const language = getLocalLanguage();
  const Axios = axios.create({ baseURL: BASE_URL });
  
  Axios.defaults.headers.common["Content-Type"] = "multipart/form-data; boundary=form-data-ApKiywFYpCtdpqEo"
  Axios.defaults.headers.common["X-Custom-Language"] = language;
  Axios.defaults.headers.common["Authorization"] = "Bearer 678e22bdb7d12c297cba77089950d84d";

  return Axios;
};

export const setHeadersAndParameters = (params, signal = false, video = false) => {
  const accessToken = sessionStorage.getItem("auth");
  const roles = getUserRoles();
  const timezone = getTimeZone();
  const language = getLocalLanguage();
  const apiKey = roles?.includes(1) ?
    "d194J8HprfVQ63u" : 
    "678e22bdb7d12c297cba77089950d84d";
  const headersWithParams = {
    headers: {
      "Content-Type": video ? "multipart/form-data" : "application/json",
      "X-Custom-Format": "html",
      "X-Custom-Language": language,
      "X-Custom-Timezone": timezone,
      Authorization: `Bearer ${apiKey}.${accessToken}`,
    },
    params: params.params,
    signal: signal
  };

  return headersWithParams;
};

export const setImageHeaders = () => {
  const accessToken = sessionStorage.getItem("auth");
  const timezone = getTimeZone();
  const language = getLocalLanguage();
  const apiKey = "678e22bdb7d12c297cba77089950d84d";

  const headers = {
    headers: {
      "X-Custom-Format": "html",
      "X-Custom-Language": language,
      "X-Custom-Timezone": timezone,
      Authorization: `Bearer ${apiKey}.${accessToken}`,
    },
  };

  return headers;
};

export const setHeaders = (video = false) => {
  const accessToken = sessionStorage.getItem("auth");
  const roles = getUserRoles();
  const timezone = getTimeZone();
  const language = getLocalLanguage();
  const apiKey = roles?.includes(1) ? API_DETAILS.baseAdminApiKey : API_DETAILS.baseApiKey;
  const headers = {
    headers: {
      "Content-Type": video ? "multipart/form-data" : "application/json",
      "X-Custom-Format": "html",
      "X-Custom-Language": language,
      "X-Custom-Timezone": timezone,
      // "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${apiKey}.${accessToken}`,
    },
  };
  return headers;
};
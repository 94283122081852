/* REACT PACKAGE IMPORTS */
import { useState, useRef } from "react";
import { Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

/* MUI PACKAGE IMPORTS */
import { Button } from "@mui/material";

/* INTERNAL IMPORTS */
import { flattenArr } from "../../utils/tools.js";
import CountdownTimer from "../Timer";
import Modal from "../Modal";
import PinModal from "../PinModal";
import {
  startSession,
  pauseSession,
  endSessionHandle,
} from "../../Features/Sessions/Actions/SessionsActions";


export default function SessionInfo({
  description,
  duration,
  passmark,
  instructions,
  sessionId,
  exId,
  timeToStartSession,
  isConducted,
  showPause,
  isSessionRunning,
  sessionExpired,
  setConducted,
  setIsSessionRunning,
  setStartTimer,
  setIsSessionCalled,
}) {
  const [sessionToStart, setSessionToStart] = useState(false);
  const [msg, setMsg] = useState("");
  const [motives, setMotives] = useState([]);
  const [button, setButton] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const formattedDuration = duration.split(":");
  const formattedDurationString = `${formattedDuration[0][1]} hours ${formattedDuration[1]} minutes`;

  const inputPinRef = useRef(null);

  const dispatch = useDispatch();

  const { questions } = useSelector(store => store.assessments);
  const { 
    sessionStatus, 
    answersSaved, 
    pages } = useSelector(store => store.sessions);

  const handleStart = () => {
    try {
      if (sessionStatus === "paused") dispatch(startSession({ esId: sessionId, exId }));
      else if (sessionStatus === "pending" || sessionStatus === "future") setShowPin(true);
      else if (sessionStatus === "started") {
        dispatch(pauseSession(sessionId));
        setIsSessionRunning(false);
      }
    } catch (error) { console.log(error); }
  };

  const handleSubmitPin = () => {
    const pinArr = [];
    inputPinRef.current.inputs.forEach(input => pinArr.push(input.value));
    const pinNum = pinArr.join("");
    dispatch(startSession({ esId: sessionId, exId, pin: pinNum }))
      .then(res => {
          if (!res.error) {
            setIsSessionRunning(true);
            setShowPin(true);
          } else setShowPin(false);
      });
    setIsSessionCalled(false);
  };

  const handleConfirmFinish = () => {
    setShowModal(true);
    let warningsArray = [];
    let questionsArray = questions.map(question => question.questions);
    var mergedQuestions = [].concat
      .apply([], questionsArray)
      .map(question => question.questions);
    var totalQuestions = flattenArr(mergedQuestions);
    if (!sessionExpired) {
      warningsArray.push(
        "There is still some time left until the end of the session."
      );
    }
    if (pages.filter(page => page.tagged).length > 0) {
      warningsArray.push("Some questions are flagged.");
    }
    if (answersSaved.length >= totalQuestions.length) { 
      warningsArray.push("There are still some unanswered questions.");
    }
    setMsg(
      "Are you sure you want to finish this test?"
    );

    setMotives(warningsArray);
    setButton("Finish");
  };

  const handleFinish = (e, i) => {
    dispatch(endSessionHandle(sessionId))
      .then(() => {
          setShowModal(false);
          setConducted(true);
          setIsSessionRunning(false);
          setStartTimer(false);
      });
  };

  return (
    <div className="align-items-center mb-4">
      <Container className="p-4 box">
        <p className="fw-bold fs-4 mb-3">Assessment Details</p>
        <p><span className="fw-bold fs-5">Description:</span> {description ? description : <span className="text-muted fst-italic">No description set</span>}</p>
        <p><span className="fw-bold fs-5">Instructions:</span> {instructions ? instructions : <span className="text-muted fst-italic">No instructions set</span>}</p>
        <p><span className="fw-bold fs-5">Duration:</span> {formattedDurationString}</p>
        {/*<p><span className="fw-bold fs-5">Pass mark:</span> {passmark}</p>*/}
        <hr />
        {
          timeToStartSession && 
          !sessionToStart ? 
            <CountdownTimer
              targetDate={timeToStartSession}
              sessionExpired={sessionToStart}
              setSessionExpired={setSessionToStart}
              paused={false}
            /> :
            <div
              className="d-flex flex-column align-items-center my-4"
            >
              <Button
                className="my-2"
                variant="contained"
                onClick={() => handleStart()}
                disabled={isConducted || (isSessionRunning && !showPause)}
              >
                {isSessionRunning ? "Pause Assessment" : "Start Assessment"}
              </Button>
              <Button
                className="my-2"
                variant="contained"
                onClick={handleConfirmFinish}
                disabled={isConducted || !isSessionRunning}
              >
                Finish Assessment
              </Button>
            </div>
        }
      </Container>
      <Modal
        setShowModal={setShowModal}
        show={showModal}
        handleSubmit={handleFinish}
        motives={motives}
        msg={msg}
        button={button}
      />
      <PinModal
        setShowModal={setShowPin}
        show={showPin}
        handleSubmit={handleSubmitPin}
        inputPinRef={inputPinRef}
      />
    </div>
  );
}
